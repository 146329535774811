import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PubSubService {
  private subjects: Subject<any>[] = [];

  private getFullEventName(eventName: string, uniqueId: string) {
    return `${eventName}_${uniqueId}`;
  }

  publish(eventName: string, uniqueId: string, data?: any) {
    eventName = this.getFullEventName(eventName, uniqueId);

    // ensure a subject for the event name exists
    this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();

    // publish event
    this.subjects[eventName].next(data);
  }

  on(eventName: string, uniqueId: string): Observable<any> {
    eventName = this.getFullEventName(eventName, uniqueId);

    // ensure a subject for the event name exists
    this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();

    // return observable 
    return this.subjects[eventName].asObservable();
  }

  once(eventName: string, uniqueId: string): Observable<any> {
    eventName = this.getFullEventName(eventName, uniqueId);

    // ensure a subject for the event name exists
    this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();

    // return observable 
    return this.subjects[eventName].pipe(take(1)).asObservable();
  }

}
