import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZAppDevSidebarComponent } from './sidebar.component';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    SidebarModule
  ],
  declarations: [ZAppDevSidebarComponent],
  providers: [],
  exports: [ZAppDevSidebarComponent]
})
export class zAppDevSidebarModule {
  static forRoot(): ModuleWithProviders<zAppDevSidebarModule> {
    return {
      ngModule: zAppDevSidebarModule,
      providers: [],
    };
  }
}
