export class DefaultSelectionUpdateEvent {
  value: any;
  makeDirty: boolean;
}

export const transformStyleToObject = (style: string): any => {
  let result = {}; 
  if (style == null || valueIsObject(style)) {
    return result;
  } 
  let attributes = style.split(';');

  for (var i = 0; i < attributes.length; i++) {
    var entry = attributes[i].trim().split(':');
    result[entry.splice(0, 1)[0]] = entry.join(':');
  }
  return result;
};

export const valueIsObject = (value: any): boolean => {
  return typeof value === 'object' &&
    value !== null &&
    ((value instanceof Boolean) === false) &&
    ((value instanceof Date) === false) &&
    ((value instanceof Number) === false) &&
    ((value instanceof RegExp) === false) &&
    ((value instanceof String) === false);
};

export const isArray = (instance: any): boolean => {
  return instance != null && instance.constructor === Array && instance.length != null;
}

export const setKeyAsClientKey = (obj: any): void => {
  if (obj == null || obj._clientKey != null || obj._key == null) return;

  obj._clientKey = obj._key;
};

// TODO: Check that key has value not only by '0' but also for each Datatype...
export const keyHasDefaultValue = (key: any): boolean => {
  if (key == null) return true;

  const keyAsString = key.toString().trim();

  return keyAsString === '0' || keyAsString === '' || keyAsString === '00000000-0000-0000-0000-000000000000';
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function regExpEscape(text: any) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return element && element.className && element.className.split &&
    element.className.split(/\s+/).indexOf(className) >= 0;
}

if (typeof Element !== 'undefined' && !Element.prototype.closest) {
  // Polyfill for ie10+

  if (!Element.prototype.matches) {
    // IE uses the non-standard name: msMatchesSelector
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  Element.prototype.closest = function (s: string) {
    let el = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export function closest(element: HTMLElement, selector): HTMLElement | null {
  if (!selector) {
    return null;
  }

  return element.closest(selector);
}


export function convertToRGB(hexColor: string) {

  if (hexColor == null) {
    return null;
  }

  hexColor = hexColor.replace('#', '');

  if (hexColor.length != 6) {
    throw "Only six-digit hex colors are allowed.";
  }

  var aRgbHex = hexColor.match(/.{1,2}/g);
  var aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16)
  ];

  return `rgb(${aRgb[0]}, ${aRgb[1]}, ${aRgb[2]})`;

}
