import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { zAppDevMenuControlModule } from '../@framework/components/MenuControl/menucontrol.module';
import { RouterModule } from '@angular/router';
import { EventsService } from './services/Events.service';

export const ZAPPDEV_CORE_PROVIDERS = [
  EventsService
];

@NgModule({
  imports: [
    RouterModule,
    zAppDevMenuControlModule
  ],
  exports: [],
  declarations: [],
  bootstrap: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...ZAPPDEV_CORE_PROVIDERS,
      ]
    };
  }
}
