
import { IDataListTextResources } from "./IDataListTextResources";


export abstract class ListControlResourcesServiceBase {

  public abstract get textResources(): IDataListTextResources;

  public abstract get dataTablesResources ();

  public abstract getIcons(): any;

  icons = {
    refresh: "fa fa-refresh",
    reset: "fa fa-repeat",
    group: "fa fa-table",
    export: "fa fa-download",
    view: "fa fa-eye",
    hideShow: "fa fa-table",
    remove: "fa fa-trash",
    filter: "fa fa-filter",
    search: "fa fa-search",
    aggregator: "fa fa-dashboard",
    menuItem: "fa fa-circle",
  };
}
