import {
  Input,
  Component,
  ElementRef,
  HostBinding,
  Output,
  EventEmitter,
} from "@angular/core";
import { zAppDevComponentSize } from "../component-size";
import { zAppDevComponentStatus } from "../component-status";

import * as ThemeOptions from "../../theme/interfaces/options";
import { IThemeSevice } from "../../theme/interfaces/theme.interface";
import { ValueFormat } from "../../core/clms.fw.angular";
import { CF_COMPONENT } from "../../rule-engine/directives/condition-formatting.directive";
import { zAppDevBaseComponent } from "../BaseComponent/base.component";

@Component({
  selector: "[zappLabel]",
  templateUrl: './label.component.html',
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevLabelComponent }]
})
export class zAppDevLabelComponent extends zAppDevBaseComponent {

  _value: any;
  private _valueFormat: ValueFormat;

  @Input()
  set value(value) {
    let valueWasNull = false;
    if (value == null || value == undefined) {
      valueWasNull = true;
      value = "";
    }
    if (this._valueFormat === undefined || this.type == "string") {
      this._value = value;
    } else {
      this._value = valueWasNull ? "" : this._valueFormat.format(value);
    }
  }

  _innerHtmlValue: any = null;
  @Input()
  set innerHTMLValue(val) {
    this._innerHtmlValue = this.getHtml(val);
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() type: any;

  @Input()
  set valueFormat(format: any) {
    if (format != null) {
      this._valueFormat = new ValueFormat(format);
    }
  }

  @Input() variation: string = "Standard";

  @Input() size: zAppDevComponentSize = "med";

  @Input() status: zAppDevComponentStatus = "global";

  @Input() class: string = "";

  options: ThemeOptions.LabelThemeOptions;

  constructor(private themeService: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.options = this.themeService.getLabelThemeOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  setValue(value: string, fromCalculated: boolean = false) {
    this.value = value;
    if (fromCalculated === true) {
      this.valueChange.emit(value);
    }
  }

  @HostBinding('class')
  get hostClasses(): string {
    return this.hostClassesArray.concat(this.styleClassesArray).join(' ');
  }

  @HostBinding('class.hidden')
  get hiddenClass() {
    return this.hidden ? "hidden" : "";
  }

  getHtml(value: any) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = value;
    const html = textArea.value;
    textArea.remove();
    return html;
  }
}
