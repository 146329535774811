import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevButtonDirective } from './button.directive';
import { SecurityModule } from '../../security/security.module';
import { zAppDevButtonComponent } from './button.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [zAppDevButtonDirective, zAppDevButtonComponent],
  imports: [
    CommonModule,
    SecurityModule,
    ButtonModule
  ],
  exports: [zAppDevButtonDirective, zAppDevButtonComponent]
})
export class zAppDevButtonModule {
  static forRoot(): ModuleWithProviders<zAppDevButtonModule> {
    return {
      ngModule: zAppDevButtonModule,
      providers: [ ],
    };
  }
}
