import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevFormModalComponent } from './form-modal.component';
import { zAppDevModalModule } from '@framework/components/Modal/modal.module';
import { zAppDevSidebarModule } from '@framework/components/Sidebar/sidebar.module';
import { RouterModule } from '@angular/router';
import { zAppDevButtonModule } from '../Button/button.module';
import { zAppDevIconismModule } from '../Iconism/iconism.module';
import { zAppDevLabelModule } from '../Label/label.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    zAppDevModalModule,
    zAppDevSidebarModule,
    zAppDevButtonModule,
    zAppDevIconismModule,
    zAppDevLabelModule
  ],
  declarations: [zAppDevFormModalComponent],
  providers: [],
  exports: [zAppDevFormModalComponent],
})
export class zAppDevFormModalModule {
  static forRoot(): ModuleWithProviders<zAppDevFormModalModule> {
    return {
      ngModule: zAppDevFormModalModule,
      providers: [],
    };
  }
}
