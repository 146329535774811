import { Injectable, RendererFactory2, Renderer2, Inject } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private _defaultThemeName = "default";
  private _localStorageKey = "selected_theme_name";
  private _theme: BehaviorSubject<string> = new BehaviorSubject(this._defaultThemeName);

  private _renderer: Renderer2;
  private head: HTMLElement;
  private themeLinks: HTMLElement[] = [];
  private baseRef = "";
  private themes: string[] = [this._defaultThemeName];

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) document: Document
  ) {
    this.head = document.head;
    this._renderer = rendererFactory.createRenderer(null, null);
    const baseEl = this.head.getElementsByTagName("base")[0];
    if (baseEl != null) {
      this.baseRef = baseEl.getAttribute("href").trim();
      if (this.baseRef.endsWith("/")) {
        this.baseRef = this.baseRef.slice(0, -1);
      }
    }

    this._theme.subscribe(async (theme) => {
      const cssFilename = theme + '.css';
      await this.loadCss(cssFilename);
      localStorage.setItem(this._localStorageKey, theme);
      if (this.themeLinks.length == 2)
        this._renderer.removeChild(this.head, this.themeLinks.shift());
    });

    this.locateDefaultTheme();
    const activeTheme = localStorage.getItem(this._localStorageKey);
    if (activeTheme != this._theme.value &&
      this.themes.indexOf(activeTheme) != -1) {
      this._theme.next(activeTheme);
    }
  }

  setThemes(themes: string[]) {
    this.themes = [...themes];
  }

  setMainTheme(name: string) {
    if (this.themes.indexOf(name) == -1) {
      name = this._defaultThemeName;
    }

    if (this._theme.value == name) {
      return "";
    }

    this._theme.next(name);
  }

  private async loadCss(filename: string) {
    return new Promise(resolve => {
      const linkEl: HTMLElement = this._renderer.createElement('link');
      this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      this._renderer.setAttribute(linkEl, 'type', 'text/css');
      this._renderer.setAttribute(linkEl, 'href', this.baseRef + "/" + filename);
      this._renderer.setProperty(linkEl, 'onload', resolve);
      this._renderer.appendChild(this.head, linkEl);
      this.themeLinks = [...this.themeLinks, linkEl];
    })
  }

  private locateDefaultTheme() {
    const links = this.head.getElementsByTagName("link");

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (link.getAttribute("href").endsWith(this.baseRef + "/" + this._defaultThemeName + ".css")) {
        this.themeLinks = [link];
        break;
      }
    }
  }
}
