import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZAppDevModalComponent } from './modal.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  imports: [
    CommonModule,
    DialogModule
  ],
  declarations: [ZAppDevModalComponent],
  providers: [],
  exports: [ZAppDevModalComponent]
})
export class zAppDevModalModule {
  static forRoot(): ModuleWithProviders<zAppDevModalModule> {
    return {
      ngModule: zAppDevModalModule,
      providers: [],
    };
  }
}
