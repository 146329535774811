import { Observable } from 'rxjs';
import { Joove } from 'src/app/@framework/core/Joove';

export abstract class IPagesService
{
    abstract refreshContext(page: string, action: string) : Observable<Joove.IContext>;
}

export abstract class IFormControlsAdapter<T, S> {
  abstract fromModel(model: T): S;
  abstract toModel(model: T, formControlsModel: S): T;
}