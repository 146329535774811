import { Injectable, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { ConfirmationService } from "primeng/api";
import { Joove } from "src/app/@framework/core/Joove";
import { FriendlyMessageDTO } from "./ServerErrorHandler.model";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})

export class ServerErrorHandlerService {
  
  constructor(private injector: Injector) { }

  public enrichExceptionMessage(error: FriendlyMessageDTO) {
    
    let translateService = this.injector.get(TranslateService);
    let message = error.Title;
    let title = "Error";
    
    if(Joove.Common.stringIsNullOrEmpty(message))
    {
      message = "Something went wrong, please contact your administrator";
    }
    if(error.ExceptionType === "StaleObjectStateException")
    {
      message = translateService.instant("RES_SITE_StaleObjectStateExceptionMessage");
      title = translateService.instant("RES_SITE_StaleObjectStateExceptionTitle");
    }

    let messageParams = {
      Message: message,
      Title: title,
      Type: "Error"
    };
    return messageParams;
  }
}

