import { Injectable } from '@angular/core';
import { Joove } from '../../core/Joove';
import { IPermissionService } from '../interfaces/permission.interface';
import { BehaviorSubject, Observable } from 'rxjs';

export const PERMISSIONS_NAME = 'permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends IPermissionService {

  constructor() {
    super();
  }

  model: Joove.IContext;
  permissions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

  updatePermissions(permissions: string[]) {
    if (permissions == null) {
      return;
    }

    this.permissions.next(permissions);
  }

  hasPermission(permissions: string[]): boolean {
    const userPermissions: string[] = this.model?.currentUserPermissions ?? this.permissions.value;


    if (userPermissions == null || permissions == null) {
      return false;
    }

    return userPermissions.some(p => permissions.some(per => per === p));
  }

  setPermissions(permissions: string[]) {
    localStorage.setItem(PERMISSIONS_NAME, JSON.stringify(permissions));
  }

}
