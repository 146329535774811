export const safeDeepPropertySet = (obj: any, key: Array<string> | string, val: any): void => {
  const keys = typeof key === 'string' ? key.replace(/\?/g, '').split('.') : key;
  let i = 0;
  const l = keys.length;
  let t = obj;
  let x: any;
  for (; i < l; ++i) {
    x = t[keys[i]];
    t = t[keys[i]] = (i === l - 1 ? val : (x == null ? {} : x));
  }
}

export const safeDeepPropertyAccess = (obj: any, key: string, def?: any): any => {
  let p = 0;
  const keys = key.split ? key.replace(/\?/g, '').split('.') : key;
  while (obj && p < keys.length) obj = obj[keys[p++]];
  return obj === undefined ? def : obj;
}
