import { Injectable } from '@angular/core';
import { transformStyleToObject } from '@framework/common';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../../@core/services/Auth.service';
import { PermissionService } from '../../security/services/permission.service';
import { zAppDevMenuItem } from './menu-item';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import { environment } from 'src/environments/environment';


@Injectable()
export class MenuInternalService {

  iconOptions: ThemeOptions.IconismThemeOptions;

  constructor(
    protected readonly permissionsService: PermissionService,
    protected readonly authService: AuthService,
    protected readonly translateService: TranslateService,
    private themeService: IThemeSevice) {

    this.iconOptions = this.themeService.getIconismThemeOptions();

  }

  prepareItem(item: zAppDevMenuItem): MenuItem {

    const hasAccess = this.hasAccess(item);
    const headerClass = item.isHeader ? ' header' : '';

    let icon = this.iconOptions.Icons[item.icon];
    let menuItemWithImageClass = '';
    let iconStyle = undefined;
    if (item.image != null && item.image.length > 0) {
      icon = 'pi pi-plus';
      menuItemWithImageClass = ' image-menu-item';
      iconStyle = {
        'background-image': `url('${environment.baseUrl}/${item.image}')`,
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center'
      }
    }

    return {
      styleClass: item.class + headerClass + menuItemWithImageClass,
      style: item.style ? transformStyleToObject(item.style) : '',
      label: item.label ? item.label() : '',
      icon,
      title: item.tooltip ? item.tooltip() : '',
      items: item.children?.map((child) => this.prepareItem(child)),
      routerLink: item.route,
      url: item.url,
      visible: hasAccess || item.authorization.action == 'DISABLE',
      disabled: !hasAccess && item.authorization.action == 'DISABLE',
      command: item.onClick,
      iconStyle
    }
  }

  prepareItems(items: zAppDevMenuItem[]): MenuItem[] {
    return items.map((item) => this.prepareItem(item));
  }

  private hasAccess(item: zAppDevMenuItem): boolean {
    let visible = false;

    if (this.authService.isLoggedOut()) {
      visible = item.authorization.allowAnonymous;
    }

    if (this.authService.isLoggedIn()) {
      if (item.authorization.allowAuthenticated) {
        visible = true;
      } else {
        visible = this.permissionsService.hasPermission(item.authorization.permissions);
      }
    }

    if (item.children != null && item.children.length > 0) {
      const childs = item.children.map((child) => this.hasAccess(child));

      if (visible) {
        visible = childs.every(child => child == false);
      } else {
        visible = childs.some(child => child == true);
      }
    }

    return visible;
  }
}
